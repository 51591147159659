/*These are google autocomplete classes
https://developers.google.com/maps/documentation/javascript/places-autocomplete#style-autocomplete */
.pac-icon {
  display: none;
}

.pac-container {
  border-radius: 0.5rem;
  box-shadow: none;
}

.pac-item {
  padding-left: 20px;
  border: none;
}

.pac-item-query {
  color: #424242;
  font-size: 16px;
}
